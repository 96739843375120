<template>
    <div class="card">
        <div v-if="loading" class="card-body">
            <div class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i> Загрузка...</div>
        </div>
        <template v-else>
        <div v-if="!act.blocked && !(!!act.step)" class="alert alert-danger text-center">Недоступно</div>
        <div v-if="!act.blocked && act.step" class="card-body">
            <div class="text-center mb-3 mt-3">
                <h5>Отчет за смену</h5>
            </div>
            <steps @step-selected="setStep($event)" :disabled="nextStepLoading || prevStepLoading" :step="act.step" class="mb-3"></steps>
        </div>
            <template v-if="!act.blocked && act.step === 1">
                <div class="card-body">
                <div class="row">
                    <div class="col-4">
                        <storage-picker v-model="act.storage"
                                        selectable-for-task-list
                                        :disabled="nextStepLoading || prevStepLoading || !!act.blocked || !!act.id"
                                        :allowed-roles="storageAllowedRoles"
                        ></storage-picker>
                    </div>
                    <div class="col-8">
                        <actual-shift-picker :disabled="nextStepLoading || prevStepLoading || !!act.blocked || !this.act.storage || !!act.id"
                                             :storage="act.storage"
                                             v-model="act.actualShift"
                        ></actual-shift-picker>
                    </div>
                </div>
                </div>
                <div class="card-footer text-right">
                    <button :disabled="nextStepLoading || tasksExistsLoading || !(!!act.storage) || !(!!act.actualShift) || !tasksExists" @click="fetchOrCreateAct()" class="btn btn-success">
                        <i v-if="nextStepLoading || tasksExistsLoading" class="fas fa-spinner fa-spin"></i>
                        Далее <i class="fas fa-angle-double-right"></i>
                    </button>
                </div>
            </template>
            <template v-if="!act.blocked && act.step === 2">
                <div class="card-body">
                    <ol class="text-readable mb-5">
                        <li>Проверьте все передачи, поступавшие в Ваш цех за эту смену</li>
                        <li>Поставьте галочку напротив передач, которые действительно поступали в Ваш цех в этой смене</li>
                        <li>Нажмите <button disabled class="btn btn-sm btn-info">Сохранить</button>, затем <button disabled class="btn btn-sm btn-success">Далее <i class="fas fa-angle-double-right"></i></button></li>
                    </ol>
                    <transfers :disabled="nextStepLoading || prevStepLoading" class="mt-5 mb-5" :act="act" :actual-shift="act.actualShift" :storage="act.storage"></transfers>
                </div>
                <div class="card-footer">
                    <button :disabled="nextStepLoading || prevStepLoading" class="btn btn-default" @click="setStep(1)"><i class="fas fa-angle-double-left"></i> Предыдущий шаг <i v-if="prevStepLoading" class="fas fa-spinner fa-spin"></i></button>
                    <button :disabled="nextStepLoading || prevStepLoading" @click="setStep(3)" class="btn btn-success pull-right">
                        <i v-if="nextStepLoading" class="fas fa-spinner fa-spin"></i>
                        Далее <i class="fas fa-angle-double-right"></i>
                    </button>
                </div>
            </template>
            <template v-if="!act.blocked && act.step === 3">
                <div class="card-body">
                    <ol class="text-readable mb-5">
                        <li>Проверьте, всю ли информацию по заданиям Вы заполнили</li>
                        <li>Поставьте галочку напротив задания, в котором хотите изменить выполненное количество</li>
                        <li>Нажмите <button disabled class="btn btn-sm btn-warning">Заполнить</button> или введите число вручную в появившемся поле</li>
                        <li>Нажмите <button disabled class="btn btn-sm btn-info">Сохранить</button>, затем <button disabled class="btn btn-sm btn-success">Далее <i class="fas fa-angle-double-right"></i></button></li>
                    </ol>
                    <tasks :disabled="nextStepLoading || prevStepLoading" :act="act" :actual-shift="act.actualShift" :storage="act.storage"></tasks>
                </div>
                <div class="card-footer">
                    <button  :disabled="nextStepLoading || prevStepLoading" class="btn btn-default" @click="setStep(2)"><i class="fas fa-angle-double-left"></i> Предыдущий шаг <i v-if="prevStepLoading" class="fas fa-spinner fa-spin"></i></button>
                    <button :disabled="nextStepLoading || prevStepLoading" @click="setStep(4)" class="btn btn-success pull-right">
                        <i v-if="nextStepLoading" class="fas fa-spinner fa-spin"></i>
                        Далее <i class="fas fa-angle-double-right"></i>
                    </button>
                </div>
            </template>
            <template v-if="!act.blocked && act.step === 4">
                <ol class="text-readable">
                    <li>Укажите фактические остатки на конец смены</li>
                    <li>Нажмите <button disabled class="btn btn-sm btn-success">Сохранить</button>, затем <button disabled class="btn btn-sm btn-success">Закрыть смену</button></li>
                </ol>
                <rests ref="rests" :show-save-button="true" @mounted="restsSaved = false" @save-rests="saveRests()" :act="act" :disabled="restsSaving || nextStepLoading || prevStepLoading || act.blocked"></rests>
                <div class="card-footer">
                    <button :disabled="nextStepLoading || prevStepLoading" class="btn btn-default" @click="setStep(3)"><i class="fas fa-angle-double-left"></i> Предыдущий шаг <i v-if="prevStepLoading" class="fas fa-spinner fa-spin"></i></button>
                    <button :disabled="nextStepLoading || prevStepLoading" @click="closeShift()" class="btn btn-success pull-right">
                        <i v-if="nextStepLoading" class="fas fa-spinner fa-spin"></i>
                        Закрыть смену
                    </button>
                </div>
            </template>
            <template v-if="!!act.blocked">
                <div class="card-body">
                    <p class="text-center text-readable">Отчет №{{act.id}} за смену <b>готов</b></p>
                    <p class="text-center text-readable">Смена <b>{{act.actualShift.name}}</b> в <b>{{act.storage.name}}</b> закрыта</p>
                    <p class="text-center">Выполненные задания:</p>
                    <production-report :act="act"></production-report>
                    <p class="text-center">Расход сырья:</p>
                    <usage-report :act="act"></usage-report>
                </div>
            </template>
        </template>
        <div class="card-footer">
            <button class="btn btn-danger" @click="$router.go(goBack)"><i class="fas fa-angle-double-left"></i> Назад</button>
        </div>
        </div>
</template>

<script>
    import StoragePicker from "../../_common/StoragePicker";
    import ActualShiftPicker from "../../_common/ActualShiftPicker";
    import Steps from "./Steps";
    import Transfers from "./Transfers/Transfers";
    import Tasks from "./Tasks/Tasks";
    import TransferRests from "./Rests/TransferRests";
    import Rests from "./Rests/Rests";
    import UsageReport from "./Report/UsageReport";
    import ProductionReport from "./Report/ProductionReport";
    import { SHIFT_CLOSING_ACT_ENDPOINT, PRODUCTION_SHIFT_ENDPOINT } from "@utils/endpoints";
    import {ROLE_STORAGE_SENIOR, ROLE_GROUP_SENIOR} from '@utils/Roles';
    import moment from 'moment';
    import BarcodeRepository from "../../../utils/BarcodeRepository";

    const WITH = [
        'storage',
        'productionShift',
        'items.nomenclature.measureUnit',
        'items.nomenclatureLot',
        'items.box',
        'actualShift.shift'
    ];

    export default {
        name: "Wizard",
        components: {
            Steps,
            StoragePicker,
            ActualShiftPicker,
            Transfers,
            Tasks,
            TransferRests,
            Rests,
            UsageReport,
            ProductionReport
        },
        props: {
            storage: {
                type: Object,
                required: false
            },
            actualShift: {
                type: Object,
                required: false
            }
        },
        watch: {
            '$route.params.id': function() {
                this.reset();
                this.init();
            },
            'act.storage': function() {
                if (this.act.storage && this.act.actualShift) {
                    this.checkExistingTasks();
                }
            },
            'act.actualShift': function() {
                if (this.act.storage && this.act.actualShift) {
                    this.checkExistingTasks();
                }
            }
        },
        computed: {
            currentActId() {
                if (!!this.act.id) {
                    return this.act.id;
                }

                if (!!this.$route.params.id) {
                    return this.$route.params.id
                }

                return null;
            },
            storageAllowedRoles() {
                return [ROLE_GROUP_SENIOR, ROLE_STORAGE_SENIOR];
            },
        },
        data() {
            return {
                ROLE_STORAGE_SENIOR,
                act: {
                    storage: null,
                    actualShift: null,
                    step: 1,
                    blocked: false,
                },
                loading: false,
                tasksExists: false,
                tasksExistsLoading: false,
                nextStepLoading: false,
                prevStepLoading: false,
                restsSaving: false,
                restsSaved: false,
                goBack: -1,
            };
        },
        methods: {
            init() {
                if (!!this.currentActId) {
                    this.load();
                } else {
                    this.act.step = 1;
                    this.$forceUpdate();
                }

                if (!!this.storage) {
                    this.act.storage = this.storage;
                }

                if (!!this.actualShift) {
                    this.act.actualShift = this.actualShift;
                }
            },
            load() {
                this.loading = true;
                this.$http.get(SHIFT_CLOSING_ACT_ENDPOINT + `/${this.currentActId}`, {
                    params: {
                        with: WITH,
                        without_loading: true
                    }
                }).then(response => {
                    this.act = response.data.data;
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else if (response.status !== 0) {
                        this.$toast.error('Ошибка!');
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            reset() {
                Object.assign(this.$data, this.$options.data.apply(this));
            },
            fetchOrCreateAct() {
                if (!!this.act.storage && !!this.act.actualShift) {
                    this.nextStepLoading = true;
                    this.$http.post(SHIFT_CLOSING_ACT_ENDPOINT + '/create_or_update',{
                        storage_id: this.act.storage.id,
                        actual_shift_id: this.act.actualShift.id,
                        production_shift_id: this.act.actualShift.shift.id,
                        date: moment(this.act.actualShift.date).format('YYYY-MM-DD'),
                        step: 2
                    },{
                        params: {
                            with: WITH,
                            without_loading: true
                        }
                    }).then(response => {
                        this.act = response.data;
                        this.$router.push({name: 'ShiftClosingWizard', params: {id: response.data.id}});
                        this.goBack--;
                    }).catch(response => {
                        if (!!response.data && !!response.data.message) {
                            this.$toast.error(response.data.message);
                        } else if (response.status !== 0) {
                            this.$toast.error('Ошибка!');
                        }
                    }).finally(() => {
                        this.nextStepLoading = false;
                    });
                }
            },
            getActData(step = null) {
                let data = {
                    id: this.currentActId,
                    storage_id: this.act.storage.id,
                    actual_shift_id: this.act.actualShift.id,
                    production_shift_id: this.act.actualShift.shift.id,
                    date: moment(this.act.actualShift.date).format('YYYY-MM-DD'),
                    step: step ? step : this.act.step,
                    blocked: !!this.act.blocked
                };

                return data;
            },
            setStep(step) {
                if (this.act.step === step) {
                    return;
                }

                let prevStep = this.act.step;

                if (prevStep < step) {
                    this.nextStepLoading = true;
                } else {
                    this.prevStepLoading = true;
                }

                this.$http.put(SHIFT_CLOSING_ACT_ENDPOINT + `/${this.act.id}`, this.getActData(step), {
                    params: {
                        with: WITH,
                        without_loading: true,
                    }
                }).then(response => {
                    this.act = response.data;
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else if (response.status !== 0) {
                        this.$toast.error('Ошибка!');
                    }
                }).finally(() => {
                        this.nextStepLoading = false;
                        this.prevStepLoading = false;
                });
            },
            closeShift() {
                if (!this.restsSaved) {
                    this.$toast.info('Сохраните остатки');
                    return;
                }

                if (!window.confirm("Это действие нельзя будет отменить.\nПродолжить?")) {
                    return;
                }

                this.$http.put(SHIFT_CLOSING_ACT_ENDPOINT + `/${this.currentActId}/block`, {
                    id: this.currentActId,
                    blocked: true
                }).then(response => {
                    this.act.blocked = response.data.blocked;
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else if (response.status !== 0) {
                        this.$toast.error('Ошибка!');
                    }
                });
            },
            saveRests() {
                this.act.items = this.$refs.rests.getItems().map(i => {
                    return {
                        id: i.id,
                        box: {name: BarcodeRepository.boxPrefixes[1] + Math.floor(Math.random() * (99999 - 10000 + 1) + 10000).toString()},
                        count: parseFloat(i.count),
                        nomenclature: i.nomenclature,
                        nomenclature_id: i.nomenclature.id,
                        nomenclature_lot_id: null,
                    };
                });

                this.restsSaving = true;
                this.$http.put(SHIFT_CLOSING_ACT_ENDPOINT + `/${this.currentActId}`, {
                    ...this.getActData(),
                    items: this.act.items
                }, {
                    params:{with: WITH}
                }).then(response => {
                    this.act = response.data;
                    this.restsSaved = true;
                }).catch(response => {
                    if (!!response.data.errors) {
                        Object.keys(response.data.errors).map(k => {
                            this.$toast.error(response.data.errors[k][0]);
                        })
                    } else if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else if (response.status !== 0) {
                        this.$toast.error('Ошибка!');
                    }
                }).finally(() => {
                    this.restsSaving = false;
                });
            },
            checkExistingTasks() {
                this.tasksExists = false;
                this.tasksExistsLoading = true;
                this.$http
                    .get(PRODUCTION_SHIFT_ENDPOINT + `/check-existing-tasks`, {
                        params: {
                            storage_id: this.act.storage.id,
                            actual_shift_id: this.act.actualShift.id,

                            without_loading: true
                        }
                    })
                    .then(response => response.data.data)
                    .then(tasksExists => {
                        this.tasksExists = tasksExists;
                        if (!tasksExists) {
                            this.$toast.error(`В ${this.act.storage.name} в эту смену не было заданий. Смену закрывать не требуется`);
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        this.$toast.error('Не удалось проверить задания на эту смену');
                    }).finally(() => {
                        this.tasksExistsLoading = false;
                    });
            },
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped lang="scss">
    .text-readable {
        font-size: 18px;
    }
</style>
