<template>
    <div class="card-body">
        <div>
        <div class="card-body text-right" style="border-bottom:1px solid #c8ced3; position: sticky; top: 55px; background-color: white; z-index: 100">
            <div class="pull-left"><b>Выпуск</b></div>
            <div class="btn-group">
                <button class="btn">Заполнить</button>
                <button class="btn btn-success">В столовую</button>
                <button class="btn btn-success">В отходы</button>
            </div>
        </div>
        <div class="table-responsive">
        <table class="table table-sm table-bordered table-hover">
            <thead class="thead-dark">
            <tr>
                <th><b-checkbox></b-checkbox></th>
                <th>Номенклатура</th>
                <th>План выпуска</th>
                <th>Факт выпуска</th>
                <th>Остаток</th>
                <th>Передать</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
                <td colspan="6">
                    <div class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i> Загрузка...</div>
                </td>
            </tr>
            <tr v-else-if="productionItems.length === 0">
                <td colspan="6">
                    <div class="alert alert-warning text-center">Нет данных</div>
                </td>
            </tr>
            <template v-else>
                <tr v-for="item in productionItems">
                    <td><b-checkbox></b-checkbox></td>
                    <td>{{item.nomenclature.name}}</td>
                    <td class="text-right">{{item.plan.toFixed(3)}}</td>
                    <td class="text-right">{{item.fact.toFixed(3)}}</td>
                    <td class="text-right">{{item.remain.toFixed(3)}}</td>
                    <td class="text-right">
                        <input type="number" class="form-control">
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
        </div>
        </div>
        <div>
        <div class="card-body text-right" style="border-bottom:1px solid #c8ced3; position: sticky; top: 55px; background-color: white; z-index: 100">
            <div class="pull-left"><b>Расход</b></div>
            <div class="btn-group">
                <button class="btn">Заполнить</button>
                <button class="btn btn-success">В столовую</button>
                <button class="btn btn-success">В отходы</button>
            </div>
        </div>
        <div class="table-responsive">
        <table class="table table-sm table-bordered table-hover">
            <thead class="thead-dark">
            <tr>
                <th style="width: 1px;">
                    <b-checkbox></b-checkbox>
                </th>
                <th>Номенклатура</th>
                <th class="text-right">Поступления</th>
                <th class="text-right">План рахода</th>
                <th class="text-right">Факт расхода</th>
                <th class="text-right">Остаток</th>
                <th class="text-right">Передать</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
                <td colspan="7">
                    <div class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i> Загрузка...</div>
                </td>
            </tr>
            <tr v-else-if="usageItems.length === 0">
                <td colspan="7">
                    <div class="alert alert-warning text-center">Нет данных</div>
                </td>
            </tr>
            <template v-else>
                <tr v-for="item in usageItems">
                    <td><b-checkbox></b-checkbox></td>
                    <td>{{item.nomenclature.name}}</td>
                    <td class="text-right">{{item.incoming.toFixed(3)}}</td>
                    <td class="text-right">{{item.plan.toFixed(3)}}</td>
                    <td class="text-right">{{item.fact.toFixed(3)}}</td>
                    <td class="text-right">{{item.remain.toFixed(3)}}</td>
                    <td class="text-right">
                        <input type="number" class="form-control" v-model="item.count">
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
        </div>
        </div>
    </div>
</template>

<script>
    import {STORAGE_MOVEMENT_ENDPOINT} from "../../../../utils/endpoints";

    export default {
        name: "TransferRests",
        props: {
            act: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                productionItems: [],
                usageItems: [],
            };
        },
        methods: {
            load() {
                if (!this.act.storage || !this.act.actualShift) {
                    return;
                }

                this.loading = true;
                this.$http.get(STORAGE_MOVEMENT_ENDPOINT + '/stuff-usage-report', {
                    params: {
                        storage_id: this.act.storage.id,
                        actual_shift_id: this.act.actualShift.id,
                        limit_by_leaving: 1,
                        // not_zero_remains: 1,

                        without_loading: true
                    }
                }).then(response => {
                    this.productionItems = response.data.production;
                    this.usageItems = response.data.usage;
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else if (response.status !== 0) {
                        this.$toast.error('Ошибка!');
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            transfer(item) {

            }
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>

</style>
