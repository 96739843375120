<template>
    <div class="card-body">
        <div v-if="!loading && waitingFor.length" class="alert alert-warning">
            Данные не окончательные!<br>
            Ожидается закрытие смен в цехах: <b>{{waitingString}}</b>
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-bordered table-hover">
                <thead class="thead-dark">
                <tr>
                    <th colspan="5">
                        Расход
                    </th>
                </tr>
                <tr>
                    <th>Номенклатура</th>
                    <th class="text-right">План расхода</th>
                    <th class="text-right">Факт расхода</th>
                    <th class="text-right">Экономия</th>
                    <th class="text-right">Перерасход</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                    <td colspan="5">
                        <div class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i> Загрузка...</div>
                    </td>
                </tr>
                <tr v-else-if="items.length === 0">
                    <td colspan="5">
                        <div class="alert alert-warning text-center">Нет данных</div>
                    </td>
                </tr>
                <template v-else>
                    <tr v-for="item in items">
                        <td>{{item.nomenclature.name}}</td>
                        <td class="text-right">{{item.plan.toFixed(3)}}</td>
                        <td class="text-right">{{item.fact.toFixed(3)}}</td>
                        <td :class="{'alert-success': saved(item)}" class="text-right">{{saved(item).toFixed(3)}}</td>
                        <td :class="{'alert-danger': overspent(item)}" class="text-right">{{overspent(item).toFixed(3)}}</td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {STORAGE_MOVEMENT_ENDPOINT} from "../../../../utils/endpoints";

    export default {
        name: "Report",
        props: {
            act: {
                type: Object,
                required: true
            }
        },
        computed: {
            waitingString() {
                let result = this.waitingFor.map(s => s.name).join('; ');
                return result;
            },
        },
        data() {
            return {
                loading: false,
                items: [],
                waitingFor: [],
            };
        },
        methods: {
            load() {

                this.loading = true;
                this.$http.get(STORAGE_MOVEMENT_ENDPOINT + '/stuff-usage-report', {
                    params: {
                        storage_id: this.act.storage.id,
                        actual_shift_id: this.act.actualShift.id,
                        only_troubles: 1,

                        without_loading: true,
                    }
                }).then(response => {
                    this.items = response.data.usage;
                    this.waitingFor = response.data.usageWaitingFor;
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else if (response.status !== 0) {
                        this.$toast.error('Ошибка!');
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            overspent(item) {
                if (item.fact <= item.plan) {
                    return 0;
                }

                return item.fact - item.plan;
            },
            saved(item) {
                if (item.plan <= item.fact) {
                    return 0;
                }

                return item.plan - item.fact;
            }
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>

</style>
