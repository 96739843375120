<template>
    <div class="mb-2">
        <span class="group-title" v-b-toggle="''+_uid">{{visible ? '-' : '+'}} <span v-html="title"></span> <b>({{tasks.length}})</b>:</span>
        <b-collapse v-model="visible" :id="''+_uid" class="pt-3">
            <div class="row pl-3 mb-2" v-for="task in tasks">
                <div class="col-3">{{task.operation.name}}</div>
                <div class="col-4">{{task.nomenclature.name}}</div>
                <div class="col-2 text-right">{{produced(task).toFixed(3)}} / {{task.count.toFixed(3)}} {{task.nomenclature.measureUnit.name}}</div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
    import moment from "moment";
    import {sumBy} from "lodash";

    export default {
        name: "TaskGroup",
        props: {
            title: {
                type: String,
                required: true
            },
            tasks: {
                type: Array,
                required: true
            },
            act: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                visible: false
            };
        },
        methods: {
            produced(task) {
                let currentShiftStartAt = moment(this.act.actualShift.start_at).format('X');
                let count = sumBy(task.productionDetails, d => {
                    let detailShiftStartAt = moment(d.actualShift.start_at).format('X');
                    if (detailShiftStartAt <= currentShiftStartAt) {
                        return parseFloat(d.count);
                    }

                    return 0;
                });

                return parseFloat(count.toFixed(3));
            },
        }
    }
</script>

<style scoped>
    .group-title {
        cursor: pointer;
    }
</style>
