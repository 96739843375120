<template>
    <div class="card-body">
        <div v-if="loading" class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i> Загргузка...</div>
        <div v-else>
            <div v-if="showDetailedStats" class="row justify-content-md-center mb-5">
                <div class="col col-lg-4 col-md-12">
                    <div class="row">
                        <div class="col col-9">
                            <h1 class="text-left">
                                {{ totalProducedCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&hairsp;&hairsp;") }} /
                                {{ totalRequiredCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&hairsp;&hairsp;") }} шт
                            </h1>
                        </div>
                        <div class="col">
                            <h1 class="text-right" :style="{'color': totalProducedPercent < 100 ? 'red' : 'green'}">
                                {{ totalProducedPercent }}%
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <b-progress height="20px" :max="totalRequiredCount" class="mb-3">
                                <b-progress-bar :value="producedFromPrevShift" :label="formatNumber(producedFromPrevShift) + ' шт'" v-if="producedFromPrevShift > 0" v-b-tooltip.hover title="Сделано из предыдущей смены" variant="warning" :striped="true" />
                                <b-progress-bar :value="producedFromCurrShift" :label="formatNumber(producedFromCurrShift) + ' шт'" v-if="producedFromCurrShift > 0" v-b-tooltip.hover title="Сделано из текущей смены" variant="success" :striped="true" />
                                <b-progress-bar :value="producedFromNextShift" :label="formatNumber(producedFromNextShift) + ' шт'" v-if="producedFromNextShift > 0" v-b-tooltip.hover title="Сделано из следующей смены" variant="info" :striped="true" />
                            </b-progress>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <h1 class="text-center"><b>{{report.completed}}</b> / {{report.total}}
                    <span :style="{'color': percentCompleted < 100 ? 'red' : 'green'}" class="ml-5"><b>{{percentCompleted}}%</b></span>
                </h1>
            </div>
            <div v-if="!!report.unfinished && report.unfinished.length" class="mb-2">
                <task-group :title="'Не выполненные (<b>будут перенесены на следующую смену</b>)'" :act="act" :tasks="report.unfinished"></task-group>
            </div>
            <div v-if="!!report.study && report.study.length" class="mb-2">
                <task-group :title="'Проработка'" :act="act" :tasks="report.study"></task-group>
            </div>
            <div v-if="!!report.etc && report.etc.length" class="mb-2">
                <task-group :title="'По дополнительным планам'" :act="act" :tasks="report.etc"></task-group>
            </div>
            <div v-if="!!report.auto_additional && report.auto_additional.length" class="mb-2">
                <task-group :title="'Доготовка по заказу'" :act="act" :tasks="report.auto_additional"></task-group>
            </div>
        </div>
    </div>
</template>

<script>
    import {PRODUCTION_TASKS_ENDPOINT} from "../../../../utils/endpoints";
    import {sumBy} from 'lodash';
    import moment from 'moment';
    import TaskGroup from './TaskGroup';

    const PACKING_STORAGE_ID = 1;
    const BOTTLING_STORAGE_ID = 28;

    export default {
        name: "ProductionReport",
        components: {
            TaskGroup
        },
        props: {
            act: {
                type: Object,
                required: true
            }
        },
        computed: {
            currentShiftStartAt() {
                return moment(this.act.actualShift.start_at)
            },
            currentShiftEndAt() {
                return moment(this.act.actualShift.end_at)
            },
            percentCompleted() {
                if (this.loading) {
                    return 0;
                }

                if (this.report.total <= 0) {
                    return 0;
                }

                return parseInt((this.report.completed/this.report.total*100).toFixed(0));
            },
            showDetailedStats() {
                const storage = this.act ? this.act.storage : {};
                return storage.id === PACKING_STORAGE_ID || storage.id === BOTTLING_STORAGE_ID;
            },
            producedFromPrevShift() {
                return this.tasks
                    .filter(task => task.fromPreviousShift)
                    .reduce((total, task) => total + (task.producedCount || 0), 0);
            },
            producedFromCurrShift() {
                return this.tasks
                    .filter(task => !task.fromPreviousShift && !task.fromNextShift)
                    .reduce((total, task) => total + (task.producedCount || 0), 0);
            },
            producedFromNextShift() {
                return this.tasks
                    .filter(task => task.fromNextShift)
                    .reduce((total, task) => total + (task.producedCount || 0), 0);
            },
            totalProducedCount() {
                return this.producedFromPrevShift + this.producedFromCurrShift + this.producedFromNextShift;
            },
            totalRequiredCount() {
                return this.tasks
                    .filter(task => !task.fromPreviousShift && !task.fromNextShift)
                    .reduce((total, task) => total + task.requiredCount, 0);
            },
            totalProducedPercent() {
                return Math.round(this.totalProducedCount / this.totalRequiredCount * 100);
            }
        },
        data() {
            return {
                loading: false,
                report: {},
                tasks: [],
                visible: {
                    unfinished: false,
                    study: false,
                    etc: false,
                    auto_additional: false,
                }
            };
        },
        methods: {
            sumBy,
            produced(task) {
                let currentShiftStartAt = moment(this.act.actualShift.start_at).format('X');
                let count = sumBy(task.productionDetails, d => {
                    let detailShiftStartAt = moment(d.actualShift.start_at).format('X');
                    if (detailShiftStartAt <= currentShiftStartAt) {
                        return parseFloat(d.count);
                    }

                    return 0;
                });

                return parseFloat(count.toFixed(3));
            },
            loadShiftStats() {
                return this.$http
                    .get(PRODUCTION_TASKS_ENDPOINT + '/shift-stats', {
                        params: {
                            actual_shift_id: this.act.actualShift.id,
                            storage_id: this.act.storage.id,

                            without_loading: true
                        }
                    })
                    .then(response => {
                        this.report = response.data;
                    })
            },
            loadProductionTasks() {
                return this.$http
                    .get(PRODUCTION_TASKS_ENDPOINT + "/dashboard", {
                        params: {
                            actual_shift_id: this.act.actualShift.id,
                            storage_id: this.act.storage.id,
                            with_next_shift_tasks: true,

                            without_loading: true
                        }
                    })
                    .then(response => {
                        this.tasks = response.data.tasks
                            .map(this.assignAssumeFinished)
                            .map(this.assignProducedCount);
                    });
            },
            assignProducedCount(task) {
                task.producedCount = 0;
                task.blockedProducedCount = 0;
                task.productionActs.forEach(productionAct => {
                    productionAct.items.forEach(item => {
                        if (item.actualShift.id === this.act.actualShift.id) {
                            task.producedCount += item.count;
                        } else {
                            task.blockedProducedCount += item.count;
                        }
                    });
                });
                task.requiredCount = Number(task.count.toFixed(3));
                task.producedCount = Number(task.producedCount.toFixed(3));
                task.blockedProducedCount = Number(
                    task.blockedProducedCount.toFixed(3)
                );
                return task;
            },
            assignAssumeFinished(task) {
                if (task.productionActs.length > 0) {
                    task.assumeFinished = task.productionActs.every(
                        productionAct => productionAct.assume_finished
                    );
                } else {
                    task.assumeFinished = false;
                }
                return task;
            },
            formatNumber(num) {
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&hairsp;&hairsp;");
            }
        },
        mounted() {
            this.loading = true;

            let loadPromise = this.loadShiftStats();

            if (this.showDetailedStats) {
                loadPromise = loadPromise
                    .then(this.loadProductionTasks);
            }

            loadPromise
                .catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else if (response.status !== 0) {
                        console.error(response);
                        this.$toast.error('Ошибка!');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
</script>

<style scoped>
    .progress-bar:hover {
        cursor: help;
    }
</style>
