<template>
    <div class="flex">
        <div class="step" :class="{'current': isCurrent(1)}">
            <div @click="setStep(1)" class="step-circle text-center" :class="stepClass(1)">
                1
            </div>
            <div class="description">
                Смена
            </div>
        </div>
        <div class="step" :class="{'current': isCurrent(2)}">
            <div @click="setStep(2)" class="step-circle text-center" :class="stepClass(2)">
                2
            </div>
            <div class="description">
                Передачи
            </div>
        </div>
        <div class="step" :class="{'current': isCurrent(3)}">
            <div @click="setStep(3)" class="step-circle text-center" :class="stepClass(3)">
                3
            </div>
            <div class="description">
                Задания
            </div>
        </div>
        <div class="step" :class="{'current': isCurrent(4)}">
            <div @click="setStep(4)" class="step-circle text-center" :class="stepClass(4)">
                4
            </div>
            <div class="description">
                Остатки
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Steps",
        props: {
            step: {
                type: Number,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        watch: {
            step(val) {
                if (!(!!val)) {
                    this.currentStep = 1;
                } else {
                    this.currentStep = val;
                }
            }
        },
        data() {
            return {
                currentStep: 1,
            };
        },
        methods: {
            isCurrent(step) {
                return this.step === step;
            },
            isPast(step) {
                return this.step > step;
            },
            isFuture(step) {
                return this.step < step;
            },
            stepClass(step) {
                return {
                    'alert-success': this.isPast(step),
                    'alert-warning': this.isCurrent(step),
                    'alert-dark': this.isFuture(step)
                };
            },
            setStep(step) {
                if (this.disabled) {
                    return;
                }

                if (this.isPast(step)) {
                    // Двигаться можно только в прошлое
                    this.$emit('step-selected', step);
                }
            }
        },
        mounted() {
            if (!(!!this.step)) {
                this.currentStep = 1;
            } else {
                this.currentStep = this.step;
            }
        }
    }
</script>

<style scoped lang="scss">
    .flex {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .step-circle {
        width: 24px;
        height: 24px;
        padding-top: 3px;
        border-radius: 12px;
        cursor: pointer;
    }

    .step {
        & + .step {
            margin-left: 50px;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                border-bottom: 1px solid black;
                width: 20px;
                top: 50%;
                left: -36px;
            }
        }

        .description {
            display: none;
        }

        &.current {
            .description {
                display: inline-block;
                max-width: 100px;
                padding-left: 10px;
            }

            .step-circle {
                display: inline-block;
                width: 50px;
                height: 50px;
                border-radius: 25px;
                color: black;
                padding-top: 8px;
                font-size: 22px;
            }
        }
    }
</style>
